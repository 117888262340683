<template>
  <span class="inline-flex gap-1">
    <button
      :disabled="loading"
      class="btn info cursor-pointer whitespace-nowrap btn smaller text-sm pt-0 pb-0 py-0"
      @click="showChauffeurs"
    >
      <i class="fas fa-users"></i> Chauffeurs
    </button>
  </span>
</template>

<script setup>
import { computed } from 'vue'

import useGetApi from '@/hooks/useGetApi'
import useNotification from '@/hooks/useNotification'

const props = defineProps({
  rit_id: Number,
  is_retour: Boolean,
})

const apiData = computed(() => ({
  rit_id: props.rit_id,
  is_retour: !!props.is_retour,
}))

const { data, getData, loading } = useGetApi('PLANNING_COLLEGAS', apiData, { skipMounted: true })

const showChauffeurs = async () => {
  await getData()

  const chauffeurs = data?.value?.list?.map(el => {
    const name = `${el.firstname || ''} ${el.lastname || ''}`.trim()
    return `<li>- ${name}</li>`
  })

  useNotification(`
    <b>Chauffeurs:</b>
    <ul class="flex flex-col gap-0.5 mt-1">
      ${chauffeurs.join('')}
    </ul>
  `, 'info')
}
</script>
